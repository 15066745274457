import React from 'react';
import {connect} from 'react-redux';
import withSizes from 'react-sizes'
import PlayerContainer from './PlayerContainer'
import {Link} from 'react-router-dom'
import Slides from './Slides';
// import FeaturedItem from './FeaturedItem';
// import FontAwesome from 'react-fontawesome';
// import ProgressiveImage from 'react-progressive-image';
import FeaturedTag from "./FeaturedTag";
import Sponsors from "./Sponsors";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import brands from '@fortawesome/fontawesome-free-brands/'
import dictionary from "../../reducers/dictionaryReducer";
import FontAwesome from "react-fontawesome";
import ProgressiveImage from "react-progressive-image";

// import api from '../../api/HomeNoticias';


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            video: false,
            width: window.innerWidth,
        }
        this.toggleVideo = this.toggleVideo.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }


    /*
    useEffect(() => {

    const fetchHomeNoticias = async () => {

        try{
        const response = await api.get('timezone/Europe');

        console.log(response);

    } catch(err){

    }

    }
    },[])

    */

    toggleVideo() {
        this.setState({
            video: !this.state.video
        });
    }

    updateDimensions() {
        this.forceUpdate();
        this.setState({
            width: window.innerWidth,
        })
        console.log('asdf');
    }

    componentDidUpdate(props, previousProps) {
        // window.scrollTo(0, 0);

    }

    componentDidMount(props, previousProps) {

        // window.scrollTo(0, 0);
        window.addEventListener("resize", this.updateDimensions);

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {


        /*

       const fetchHomeNoticias = async () => {

       console.log("run");

       try{
       const response = await api.get('http://worldtimeapi.org/api/timezone/Europe');

       console.log(response,"test");

       console.log("aaad");

       } catch(err){

       console.log(err.response.data);
       console.log(err.response.status);
       console.log(err.response.header);
       console.log("errror");


       }

       }

   */

/*
        fetch('http://worldtimeapi.org/api/timezone/Europe')
            .then(response => response.json())
            .then(data => console.log(data));

        console.log("test api");
*/

        const {items, tags, options, isMobile, dictionary, location, match} = this.props;
      //  console.log(isMobile);


        return (

            <section id="section-master" className="section-master">


                <section id="slider" className="container slides">
                    <Slides isMobile={isMobile}/>
                </section>


                <section id="home-noticias" className="container featured skew">
                    <div className="overlay overlay-hidden"></div>
                    <div className="projects-container">


                        <a href="javascript:void(0)" target="_blank" className="project home_noticias link_home_noticia"
                           style={{width: '100% !important'}}>
                            <div className="project-container">
                                <div className="overlay overlay-hidden">
                                    <div className="data-table">
                                        <div className="data-cell">
                                            <div className="button">
                                                <FontAwesome name='angle-double-down'/>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="aspect-image">
                                    <img src={options.baseUrl + 'assets/img/featured-small.jpg'} alt="#"/>
                                </div>

                                <div className="background-container">
                                    <ProgressiveImage src={options.baseUrl + 'assets/img/home-streaming.jpg'}
                                                      placeholder={options.baseUrl + 'assets/img/preloader-712x484.gif'}>
                                        {(src) => <img className="image_home_noticia"/>}
                                    </ProgressiveImage>

                                </div>


                                <div className="project-text project-text_home_noticia">
                                    <h3 className="titulo_home_noticia"></h3>
                                    <span className="descripcion_home_noticia"></span>
                                </div>
                            </div>
                        </a>


                    </div>


                </section>


                <section id="home" className="container featured skew">
                    <div className="overlay overlay-hidden"></div>
                    <div className="projects-container">
                        {items.filter(t => t.list).map((i, index) => {
                            return <FeaturedTag item={i} key={'item' + i.id} options={options}/>
                        })}

                        <a href="//untrefmedia.com/umstreaming" target="_blank" className="project">
                            <div className="project-container">
                                <div className="overlay overlay-hidden">
                                    <div className="data-table">
                                        <div className="data-cell">
                                            <div className="button">
                                                <FontAwesome name='angle-double-down'/>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="aspect-image">
                                    <img src={options.baseUrl + 'assets/img/placeholder712x484.png'} alt="#"/>
                                </div>

                                <div className="background-container">
                                    <ProgressiveImage src={options.baseUrl + 'assets/img/home-streaming.jpg'}
                                                      placeholder={options.baseUrl + 'assets/img/preloader-712x484.gif'}>
                                        {(src) => <img src={src}
                                                       alt={options.baseUrl + 'assets/img/home-streaming.jpg?v=68767'}/>}
                                    </ProgressiveImage>

                                </div>


                                <div className="project-text">
                                    <h3>
                                        Streaming
                                    </h3>
                                </div>
                            </div>
                        </a>
                        {this.state.width > 1280 ?

                            <Bloques options={options}/>


                            : <Bloques clase={'solo-txt'} options={options}/>}


                    </div>


                </section>

                {this.state.width < 1281 ?

                    <Bloques clase={'solo-redes'} options={options}/>


                    : ''}

                <section id="sponsors" className="container sponsors">
                    <Sponsors location={location} match={match}/>

                </section>


            </section>

        )
    }
}


const Bloques = ({clase, options}) => {


    return (
        <div id="bloques" className={clase ? clase + ' container bloques' : 'container bloques'}>
            <div className="aspect-image">
                <img src={options.baseUrl + 'assets/img/placeholder712x484.png'} alt="#"/>
            </div>

            <div className="project-text">
                {/*<div className="bloque noticias">
                    <a href={`https://www.youtube.com/watch?v=lU8JwfzdNyc`} target="_blank">
                        <em>{options.lang == 'es' ? 'Ya se puede ver en youtube "El baile del sapito", un video animado que realizamos para Reino Infantil.' : 'Now you can watch on YouTube "El baile del sapito", an animated video that we made for "El Reino Infantil"'}</em>
                    </a>
                </div>*/}
                <div className="bloque">
                    <div className="rojo">
                        {/*<Link to={`//`} target="_blank">
                        <FontAwesomeIcon icon={['fab', 'behance']} />
                      </Link>*/}
                        <Link to={`//twitter.com/untrefmedia`} target="_blank">
                            <FontAwesomeIcon icon={['fab', 'twitter']}/>
                        </Link>
                        <Link to={`//www.facebook.com/untrefmedia`} target="_blank">
                            <FontAwesomeIcon icon={['fab', 'facebook-f']}/>
                        </Link>
                        <Link to={`//www.instagram.com/untrefmedia/`} target="_blank">
                            <FontAwesomeIcon icon={['fab', 'instagram']}/>
                        </Link>
                        <Link to={`//www.linkedin.com/company/untref-media`} target="_blank">
                            <FontAwesomeIcon icon={['fab', 'linkedin']}/>
                        </Link>
                        <Link to={`//vimeo.com/untrefmedia`} target="_blank">
                            <FontAwesomeIcon icon={['fab', 'vimeo-v']}/>
                        </Link>
                        <Link to={`//www.youtube.com/channel/UCWRwRk8jnnhV_rvivYazSMg`} target="_blank">
                            <FontAwesomeIcon icon={['fab', 'youtube']}/>
                        </Link>
                    </div>
                    <div className="violeta">
                        <p>info@untrefmedia.com</p>
                    </div>
                </div>
            </div>

        </div>

    )
}

const mapStateToProps = (state, ownProps) => {
 {/*  console.log(ownProps);  */}
    return {
        items: state.tags,
        tags: state.tags,
        options: state.options,
        dictionary: state.dictionary,
        location: ownProps.location,
        match: ownProps.match,
    }
}

const mapSizesToProps = ({width}) => ({
    isMobile: width < 480,
})

export default connect(mapStateToProps)(withSizes(mapSizesToProps)(Home))
