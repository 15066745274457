import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';


const Sponsor = ({sponsor,options})=>{
    return (
        <div className="sponsor-item">
            <a href={sponsor.link}>
                <img src={sponsor.img} alt={sponsor.title} target="_blank"/>
            </a>


        </div>
    )
}
const Sponsors = ({sponsors, options, location,match}) => {

    console.log(match);

    console.log(match.params.slug);
    console.log(match.params.slug);
    let filter = match.params.slug === "comunicacion-y-eventos" ? s=>s.home===false : s=>s.id;

    return (
        <div className="sponsors-items">

            {sponsors.filter(filter).map( (s, index)=>{
                return <Sponsor key={s.id} sponsor={s} options={options}></Sponsor>
            } )}



    </div>
    );
}


Sponsors.propTypes={
    sponsors:PropTypes.array.isRequired,
    options:PropTypes.object.isRequired,
}

const mapStateToProps = (state,ownProps)=>{
    console.log(ownProps);
    return{

        sponsors: state.sponsors,
        options: state.options,
        location:ownProps.location,
        match:ownProps.match,

    }
}
export default connect(mapStateToProps)(Sponsors)