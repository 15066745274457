//import * as types from '../actions/actionTypes';
import initialState from '../store/initialState';

const langs = (state = initialState.langs, action) => {
    switch (action.type) {

        default:
            return state
    }
}

export default langs;
