import * as types from '../actions/actionTypes';
import initialState from '../store/initialState';

function merge(old, updated) {
    var o = {};

    old.forEach(function (v) {
        o[v.id] = v;
    })

    updated.forEach(function (v) {
        o[v.id] = v;
    })

    var r = [];

    for (var p in o) {
        if (o.hasOwnProperty(p))
            r.push(o[p]);
    }

    return r;
}

const items = (state = initialState.items, action) => {
    let merged;
    switch (action.type) {

        case types.GET_ITEMS_SUCCESS:
             merged = merge(state, action.items);
            return merged
        case types.GET_TAG_ITEMS_SUCCESS:
             merged = merge(state, action.items);
            return merged
        // return [
        //     ...state,
        //     ...action.items
        // ]
        default:
            return state
    }
}

export default items;
