import * as types from './actionTypes';
import axios from 'axios';
import {beginAjaxCall} from "./ajaxStatusActions";

export const sendEmail = (state)=>{




    return (dispatch,getState)=> {



        const params = new URLSearchParams();
        params.append('contactName', state.contactName);
        params.append('contactEmail', state.contactEmail);
          params.append('contactMessage',  state.contactMessage);
        axios.post('https://rest.untrefmedia.com/contacto', params)
            .then(function (response) {
                console.log(response);
            if(response.status )
                dispatch({type:types.SEND_MAIL_SUCCESS})
            })
            .catch(function (error) {
                // disparar error mail
                dispatch({type:types.SEND_MAIL_ERROR});

                return true;
            })
            .catch(function (error) {
                return false
            });
    }

};



export const resetSendMail =  (state)=>{

    return (dispatch,getState)=> {

        dispatch({type:types.RESET_MAIL_SUCCESS})
    }
}



