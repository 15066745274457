import * as actiontypes from '../actions/actionTypes';
import initialState from '../store/initialState';

const options = (state = initialState.options, action) => {

    switch (action.type) {
        case actiontypes.SET_LANGUAGE:
            return {
                ...state,
                lang: action.lang
            };

        default:
            return state
    }
}

export default options;
