import * as types from '../actions/actionTypes';
import initialState from '../store/initialState';

const tags  = (state = initialState.tags, action) => {
    switch (action.type) {

        case types.GET_TAG_ITEMS_SUCCESS:


            const newState = state.map((item,index)=>{
                if(item.slug === action.tag){

                    return {
                        ...item,
                        items:action.items,
                    }
                }else{
                    return item
                }

            });

            return newState;

        default:
            return state
    }
}

export default tags;
