import React from 'react';
import FontAwesome from 'react-fontawesome';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import Plyr from 'plyr-react';
class PlayerContainer extends React.Component {
    componentDidUpdate(props, previousProps) {
        window.scrollTo(0, 0);
    }

    componentDidMount(props, previousProps) {
        window.scrollTo(0, 0);
    }

    render() {
        return (

                <div className="player-container">
                    <article>
                        <header>
                            <div className="close-icon" onClick={this.props.toggleVideo}>
                                <FontAwesome name='times'/>
                            </div>

                        </header>

                            <Plyr
                                type='youtube' // or "vimeo"
                                videoId={"MAD4scq61Ys"}
                                autoplay={true}
                                settings={['speed']}
                            />


                    </article>

                </div>


        )
    }
}

export default PlayerContainer;
