//import * as types from '../actions/actionTypes';
import initialState from '../store/initialState';

const dictionary = (state = initialState.dictionary, action) => {
    switch (action.type) {

        default:
            return state
    }
}

export default dictionary;
