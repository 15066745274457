import React from 'react';
import ReactDOM from 'react-dom';
// import './styles/css/app.css';
import Root from './components/Root';
import {setConnectivity} from './actions/connectivityActions';
import { setLanguage} from "./actions/optionsActions";

import { getItems} from './actions/itemsActions';

import configureStore from './store/configureStore';
import initialState from './store/initialState';

const store = configureStore(initialState)


store.dispatch(setConnectivity());


 // store.dispatch(getItems());

if(window.location.pathname.startsWith('/en') ){
    store.dispatch(setLanguage('en'));
}else{
    store.dispatch(setLanguage('es'));
}

if(window.location.pathname=== '/'){
    window.location.href = "/es";

}

ReactDOM.render(
    <Root store={store}/>,
    document.getElementById('root'));
