export const SET_LANGUAGE = "SET_LANGUAGE";
export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";

export const SET_CONNECTIVITY = "SET_CONNECTIVITY";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_TAG_ITEMS_SUCCESS = "GET_TAG_ITEMS_SUCCESS";

export const  SEND_MAIL_SUCCESS = "SEND_MAIL_SUCESS";
export const  SEND_MAIL_ERROR = "SEND_MAIL_ERROR";
export const  RESET_MAIL_SUCCESS = "RESET_MAIL_SUCCESS";
