import * as types from '../actions/actionTypes';
import initialState from '../store/initialState';

const sendMail  = (state = initialState.sendMail, action) => {
    switch (action.type) {
        case types.SEND_MAIL_SUCCESS:
            return {
                status: true,
                message:"success",
            };
        case types.SEND_MAIL_ERROR:
            console.log('sdf');
            return {
                status: false,
                message:action.payload
            };
		case types.RESET_MAIL_SUCCESS:
            return null
        default:
            return state
    }
}

export default sendMail;
