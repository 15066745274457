import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as contactActions from '../../actions/contactActions';
import {connect} from "react-redux";
import withSizes from "react-sizes";
import {bindActionCreators} from "redux";



class Contact extends React.Component {


    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            contactName: '',
            contactEmail: '',
            contactMessage: '',
            sending: false,
            success: false,
            error: false,

            errors: [],
        }
        this.sendEmail = this.sendEmail.bind(this);
        this.changeVal = this.changeVal.bind(this)
    }


    changeVal(ev) {
        console.log(ev.target.name)
        this.setState({
            [ev.target.name]: ev.target.value,
        })
    }

    sendEmail(ev) {
        ev.preventDefault();
        this.props.actions.sendEmail(this.state);
        console.log(this.props);

    }

    componentDidMount() {
        this.props.actions.resetSendMail(this.state);
    }

    componentWillUnmount() {
        console.log(this.state)
        //     this.props.actions.resetSendMail(this.state);

        console.log("unmount ");

        if (this.props.sendMail) {

            console.log("ok");

            this.props.actions.resetSendMail(this.state);
            console.log(this.props);

        }

    }


    render() {
        const {dictionary, options} = this.props;
        const lang = dictionary[options.lang];

        return (<div>
            {this.props.sendMail === null ?

                <section id="contact">



                    <div className="in-frame">
                        <h1>{lang.contact}</h1>


                        <div className="columns">

                            <div className="col">

                                <div className={'row'}>
                                    <form action="" id="contactForm" name="contactForm">

                                        <div>
                                            <label htmlFor="contactName">{lang.name}<span className="required">*</span></label>

                                            <input onChange={this.changeVal} type="text" value={this.state.contactName}
                                                   size="35" id="contactName" name="contactName"/>
                                        </div>

                                        <div>
                                            <label htmlFor="contactEmail">Email<span
                                                className="required">*</span></label>
                                            {this.state.contactEmail.length > 0 ?


                                                <div style={{
                                                    fontSize: '13px',
                                                    marginBottom: '5px'
                                                }}>{(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.contactEmail)) ? lang.email_error : ""}</div>
                                                : ''}
                                            <input onChange={this.changeVal} value={this.state.contactEmail}
                                                   type="text" size="35" id="contactEmail" name="contactEmail"/>
                                        </div>


                                        <div>
                                            <label htmlFor="contactMessage">{lang.message}<span
                                                className="required">*</span></label>
                                            <div style={{
                                                fontSize: '13px',
                                                marginBottom: '5px'
                                            }}>{this.state.contactMessage.length > 15 ? "" : lang.short_text}</div>
                                            <textarea onChange={this.changeVal} cols="50" rows="15" id="contactMessage"
                                                      name="contactMessage">
                                        {this.state.contactMessage}
                                    </textarea>
                                        </div>


                                        <div>

                                            {
                                                this.state.contactName && this.state.contactEmail && this.state.contactMessage && this.state.contactMessage.length > 15 && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.contactEmail) ?
                                                    <button onClick={this.sendEmail}
                                                            className="submit">{lang.send}</button>
                                                    :
                                                    ""
                                            }
                                            {
                                                this.state.sending ?

                                                    <span id="image-loader">
                                                        <img alt=""
                                                             src="http://untref.edu.ar/sitios/enlaces/wp-content/themes/enlaces/images/loader.gif"/>
                                                    </span>

                                                    :
                                                    ""
                                            }


                                        </div>

                                    </form>
                                </div>
                                {
                                    this.state.error ?

                                        <div id="message-warning">Error</div>
                                        : ""
                                }


                            </div>




                        </div>
                    </div>

                </section>
                : ""}

            {this.props.sendMail ?
                this.props.sendMail.status === true ?

                    <div id="message-success">
                        <div className="in-frame">
                            <div className="columns">
                                <div className="col">
                                    <div className={'row'}>
                                        <i className="fa fa-check"></i>{lang.successmsg}<br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div id="message-error">
                        <div className="in-frame">
                            <div className="columns">
                                <div className="col">
                                    <div className={'row'}>
                                        <i className="fa fa-check"></i>{lang.errormsg}<br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                :
                ""


            }

        </div>)
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        sendMail: state.sendMail,
        dictionary: state.dictionary,
        options: state.options,

    }
}

export const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({...contactActions}, dispatch)

    }
}

const mapSizesToProps = ({width}) => ({
    isMobile: width < 480,
})


Contact.propTypes = {
    options: PropTypes.object.isRequired,
    dictionary: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withSizes(mapSizesToProps)(Contact))


