import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class About extends React.Component {


    setContent(html){
        return {__html:html};
    }
    render() {

        const {dictionary, options} = this.props;
        const lang = dictionary[options.lang];
        return (<div>

            <div className="us-container">
                <div className="in-frame">
                    <h1>{lang.us}</h1>

                    <div className="columns">
                        <div className="col">
                            <div className="row">
                                <div className="person featured">
                                    <h4>{lang.director}</h4>
                                    <p>Federico Consiglieri</p>
                                </div>

                                {/*<div className="person">*/}
                                {/*<h4>{lang.content_director}</h4>*/}
                                {/*<p>Federico Consiglieri</p>*/}
                                {/*</div>*/}

                                <div className="person">
                                    <h4>{lang.institutional_relations}</h4>
                                    <p>Carla Paredes</p>
                                </div>

                                <div className="person">
                                    <h4 dangerouslySetInnerHTML={this.setContent(lang.project_management)} />
                                    <p>Carolina Rinaldi<br/>
                                        Brenda Bechara<br/>
                                        Carolina Couto</p>
                                </div>

                                {/*<div className="person">*/}
                                {/*<h4>{lang.prospects_development}</h4>*/}
                                {/*<p>Mercedes Cerrotta</p>*/}
                                {/*</div>*/}

                                {/*<div className="person">*/}
                                {/*<h4>{lang.formats_development}</h4>*/}
                                {/*<p>Sabrina Blanco</p>*/}
                                {/*</div>*/}

                                <div className="person">
                                    <h4 dangerouslySetInnerHTML={this.setContent(lang.audiovisual_content)} />
                                    <p>Gabriela Palermo<br/>
                                        Maximiliano Recchia<br/>
                                        Santiago Camarda<br/>
                                        Vanina Carrasco<br/>
                                        Nicolás Bonanatta<br/>
                                        Julián Franzil<br/>
                                        Juan Cavedo
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col">
                            <div className="row">


                                <div className="person">
                                    <h4>{lang.script}</h4>
                                    <p>Gerardo Pranteda</p>
                                </div>

                                <div className="person">
                                    <h4>{lang.international_contents}</h4>
                                    <p>Rodrigo Cantisano</p>
                                </div>


                                <div className="person">
                                    <h4>{lang.art_design}</h4>
                                    <p>Claudio Neira<br/>
                                        Luciana Pascucci<br/>
                                        Sofia Rinaldi
                                    </p>
                                </div>
                                <div className="person">
                                    <h4>{lang.musical_prod}</h4>
                                    <p>Carlos Tanoni</p>
                                </div>

                                <div className="person">
                                    <h4>{lang.apps_web}</h4>
                                    <p>Guillermo Suárez<br/>
                                        Iván Flores<br/>
                                        Juan Manuel Bonifacio<br/>
                                        Jonathan Rikles
                                    </p>
                                </div>

                                {/*<div className="person">*/}
                                {/*<h4>{lang.content_management}</h4>*/}
                                {/*<p>*/}
                                {/*</p>*/}
                                {/*</div>*/}

                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="person">
                                    <h4>{lang.media_management}</h4>
                                    <p>Federico Blesa</p>
                                </div>

                                <div className="person">
                                    <h4>{lang.postproduction}</h4>
                                    <p>Pablo Glancszpigel<br/>
                                        Diego Acosta<br/>
                                        Carla Martin<br/>
                                        Javier Aguilar<br/>
                                        Mariano Daneri<br/>
                                        Franco Heredia<br/>
                                        Emanuel Barrera
                                    </p>
                                </div>

                                <div className="person">
                                    <h4>{lang.shootings}</h4>
                                    <p>Julián Calichio<br/>
                                        Gastón Zalazar</p>
                                </div>

                                <div className="person">
                                    <h4>{lang.technical}</h4>
                                    <p>Federico Blesa<br/>
                                        Thais Álvarez Feliu</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>)
    }
}

About.propTypes = {
    options: PropTypes.object.isRequired,
    dictionary: PropTypes.object.isRequired,
}

const mapStateToProps  = (state,ownProps)=>{
    return{
        dictionary:state.dictionary,
        options: state.options,
    }

}

export default connect(mapStateToProps)(About);
