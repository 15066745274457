import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ProgressiveImage from 'react-progressive-image';
// import Plyr from 'react-plyr';
import isNumber from 'is-number';
import * as itemsActions from "../../actions/itemsActions";
import {bindActionCreators} from "redux";

class Assets extends React.Component {

    render() {
        return (

            <div></div>
        )
    }
}

class Work extends React.Component {
    static contextTypes = {
        router: () => true, // replace with PropTypes.object if you use them
    }

    parseVideo(url) {

        url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

        if (RegExp.$3.indexOf('youtu') > -1) {
            var type = 'youtube';
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
            var type = 'vimeo';
        }

        return {
            type: type,
            id: RegExp.$6
        };
    }

    componentDidUpdate(props, previousProps) {
        window.scrollTo(0, 0);

        if(this.props.item){
            document.title = this.props.item.title;
            console.log(this.removeHtml(this.b64_to_utf8(this.props.item.description)));

            let meta_descript = this.removeHtml(this.b64_to_utf8(this.props.item.description));

            document.querySelector('meta[name="description"]').setAttribute("content", meta_descript);
            console.log("sdasd");

        }

    }

    componentWillReceiveProps(nextProps) {

        if(this.props.match.params.slug !== nextProps.match.params.slug) {
            //...action here
            // if(this.props.items.length === 0) {
            this.props.actions.getItem(nextProps.match.params.slug);
            // }
        }
    }
    componentDidMount(props, previousProps) {
        window.scrollTo(0, 0);

        // if(this.props.item === null){
        //this.props.actions.getItem(this.props.match.params.slug);

        // this.props.actions.getItems();
        // }



    }
    shouldComponentUpdate(){
        return true;
    }

    createMarkup(html) {
        return {__html: html};
    }

    b64_to_utf8(str) {

        if(str!==''){
            return decodeURIComponent(escape(window.atob(str)));
        }else{
            return '';
        }

    }


    removeHtml(str){

        let div = document.createElement("div");
        div.innerHTML = str;
        return div.textContent || div.innerText || "";

    }


    render() {
        const {item, tags, options,ajaxCallInProgress,dictionary} = this.props;

        let videoType;

        let title
        let fichaTecnica
        let description2




        if (item) {

            if (isNumber(item.video)) {
                videoType = "https://player.vimeo.com/video/"+item.video+"?embedparameter=value";
            } else {
                videoType = "https://www.youtube.com/embed/"+item.video+"?autoplay=1&mute=1&loop=1";
            }

            title = item.title;

            fichaTecnica = item.ficha_tecnica;
            description2 = item.description;
            if (options.lang !== 'es') {
                title = item.title_en;
                fichaTecnica = item.ficha_tecnica_en;
                description2 = item.description_en;

            }

        }

/*
console.log(item.video);
console.log("ssss");
console.log(videoType);
*/

        return (

            <section className="work-single">

                { ajaxCallInProgress > 0 ? <div className="loader"> <span>{dictionary[options.lang].loading}</span></div> : ""  }
                {item ?
                    <div>
                        <div className="info-container">
                            <ul>{item !== null?
                                tags.filter(t=>t.list).map((t, i) => {

                                        let tagname = t.name;
                                        if (options.lang !== 'es') {
                                            tagname = t.name_en;

                                        }
                                    let linka
                                    let target
                                    if(t.linkex !== true) {
                                        linka = '/'+options.lang+'/tags/' + t.slug;
                                    } else {
                                        linka = t.slug;
                                        target = '"_blank"';
                                    }
                                        return (
                                            <li key={'tag-' + t.slug}>
                                                <a href={linka} target={target}>{tagname}</a>
                                            </li>
                                        )
                                    }
                                )

                                : ''}
                            </ul>

                            <div className="dropdown">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default" data-toggle="dropdown">
                                        { dictionary[options.lang].setCategory}
                                    </button>
                                    <span className="caret"></span>
                                    <ul className="dropdown-menu">
                                        {tags.filter(t=>t.list).map((t, i) => {

                                                let tagname = t.name;


                                                if (options.lang !== 'es') {
                                                    tagname = t.name_en;

                                                }

                                            let linka
                                            let target
                                            if(t.linkex !== true) {
                                                linka = '/'+options.lang+'/tags/' + t.slug;
                                            } else {
                                                linka = t.slug;
                                                target = '"_blank"';
                                            }

                                                return (
                                                    <li key={'tag-' + t.slug}>
                                                        <a href={linka} target={target}>{tagname}</a>
                                                    </li>
                                                )
                                            }
                                        )}

                                    </ul>
                                </div>
                            </div>

                            {/*<Link to="#" className="btn-reel">Reel 018</Link>*/}
                        </div>
                        <article>
                            <ReactCSSTransitionGroup
                                transitionName="single-header"
                                transitionAppear={true}
                                transitionAppearTimeout={500}
                                transitionEnter={false}
                                transitionLeave={false}>

                            </ReactCSSTransitionGroup>
                            <div className="hero-content">
                                <ProgressiveImage src={item.featured_image_ficha}
                                                  placeholder={item.featured_image_ficha_preview}>
                                    {(src) => <img src={src} alt='an image'/>}
                                </ProgressiveImage>
                            </div>
                            <div className="content">
                                <div className="description">
                                    <div className="ficha-tecnica">

                                        <h3 className="title-single-work">{title ? title: ""}</h3>
                                        {/* <h5 className="subtitle-single-work">{tags.map(t => <div className="tag"
                                                                                         key={t.slug}>{t.name}</div>)}</h5> */}
                                        {/*<p>InterÃ©s general<br/>*/}
                                        {/*Cantidad de episodios: 13<br/>*/}
                                        {/*DuraciÃ³n: 45 minutos<br/>*/}
                                        {/*AÃ±o: 2016</p>*/}

                                        {item ?
                                            <div className="flex">

                                                { description2 ?
                                                    <div className="description2"
                                                         dangerouslySetInnerHTML={this.createMarkup(this.b64_to_utf8(description2))}></div>
                                                    : ''}

                                                { fichaTecnica ?
                                                    <div className="fichaTecnica"
                                                         dangerouslySetInnerHTML={this.createMarkup(this.b64_to_utf8(fichaTecnica))}></div>
                                                    :''}

                                            </div>
                                            : ""}


                                    </div>

                                </div>
                                <div className="assets">


                                    <div className="asset">
                                        {/*{*/}
                                        {/*item.video != '' ?*/}
                                        {/*<div className="videoWrapper">*/}
                                        {/*<iframe src={"https://player.vimeo.com/video/" + item.video} width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>*/}
                                        {/*/!* <iframe width="560" height="315"*/}
                                        {/*src={"https://player.vimeo.com/video/" + item.video}*/}
                                        {/*frameBorder="0" allow="autoplay; encrypted-media"/> /}
                                        {/*</div>*/}
                                        {/*:*/}
                                        {/*""*/}
                                        {/*}*/}
                                        {item.video != '' ?
                                            <div className="videoWrapper">

                                    <iframe width="100%" height="100%" src={videoType} frameborder="0"  encrypted-media allowfullscreen></iframe>

                                                {/*<Plyr*/}
                                                {/*    type={videoType} // or "vimeo"*/}
                                                {/*    videoId={item.video}*/}
                                                {/*/>*/}
                                            </div>
                                            : ""}
                                    </div>
                                    <div className="asset image">
                                        {item.images.map((i, index) => {
                                            // console.log(i);
                                            return <div className="thumbnail" key={index + 'image'} style={{
                                                // background: "url(" + options.baseUrl + "assets/img/gal-1-amigos.jpg)",
                                                backgroundSize: "cover"
                                            }}>

                                                <ProgressiveImage src={i.quarter}
                                                                  placeholder={options.baseUrl + 'assets/img/preloader-712x484.gif'}>
                                                    {(src) => <img src={src} alt='an image'/>}
                                                </ProgressiveImage>

                                            </div>

                                        })
                                        }

                                    </div>
                                </div>
                            </div>

                        </article>
                    </div>
                    : ''}


            </section>

        )
    }
}

Work.contextTypes = {
    router: PropTypes.object,
}
Work.propTypes = {

    item: PropTypes.object,
    tags: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    ajaxCallInProgress:PropTypes.number.isRequired,
    dictionary:PropTypes.object.isRequired,

}

const mapStateToProps = (state, ownProps) => {

    if (state.items.length > 0)  {
        console.log('aqui');
        console.log(state.items);

        const item = state.items.filter((i) => {
            return i.slug === ownProps.match.params.slug
        })[0];
        const tags = state.tags.filter((t) => {

            return item.tags.indexOf(t.id) !== -1

        })

        return {
            item: item,
            tags: state.tags,
            options: state.options,
            ajaxCallInProgress: state.ajaxCallInProgress,
            dictionary: state.dictionary
        }

    } else {

        return {
            item: null,
            tags: state.tags,
            options: state.options,
            ajaxCallInProgress: state.ajaxCallInProgress,
            dictionary: state.dictionary
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(itemsActions, dispatch)
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(Work)
