import {combineReducers} from 'redux';
import featuredItems from './featuredItemsReducer';
import items from './itemsReducer';
import tags  from './tagsReducer';
import options  from './optionsReducer';
import slides  from './slidesReducer';
import langs  from './langsReducer';
import sponsors  from './sponsorsReducer';
import connectivity  from './connectivityReducer';
import ajaxCallInProgress from "./ajaxStatusReducer";
import dictionary from "./dictionaryReducer";
import sendMail from "./sendMailReducer";

const rootReducers = combineReducers({
    items,
    featuredItems,
    tags,
    options,
    langs,
    slides,
    sponsors,
    connectivity,
    ajaxCallInProgress,
    dictionary,
    sendMail,
});

export default rootReducers;
