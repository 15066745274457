//import * as types from '../actions/actionTypes';
import initialState from '../store/initialState';

const slides  = (state = initialState.slides, action) => {
    switch (action.type) {

        default:
            return state
    }
}

export default slides;
