//import * as types from '../actions/actionTypes';
import initialState from '../store/initialState';

const featuredItems = (state = initialState.featuredItems, action) => {
    switch (action.type) {

        default:
            return state
    }
}

export default featuredItems;
