import React from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import App from './App'
// import Empty from './Empty';

const Root = ({store}) => (
    <Provider store={store}>
        <div>


            <Router>
                <Route path="/:lang/:filter?" component={App}/>
                {/*<App/>*/}
            </Router>
            {/*<Router>*/}
                {/*<Route path="/works/:filter?" component={Empty}/>*/}
            {/*</Router>*/}
        </div>
    </Provider>
)

Root.propTypes = {
    store: PropTypes.object.isRequired
}

export default Root
