import * as types from '../actions/actionTypes';
import initialState from '../store/initialState';

const connectivity  = (state = initialState.connectivity, action) => {
    switch (action.type) {
        case types.SET_CONNECTIVITY:
            return action.speed
        default:
            return state
    }
}

export default connectivity;
