import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Home from './Home';
import Tage from './Tags/Tag';
import Tag from './Tags/TagE';
import Works from './Works';
import Work from './Works/Work';
import About from './About';
import Contact from "./Contact";
import {Switch, Route, Link, NavLink} from 'react-router-dom';
import {connect }from 'react-redux';
import {bindActionCreators} from "redux";
import * as optionActions from "../actions/optionsActions";
import * as itemsActions from "../actions/itemsActions";
import PlayerContainer from './Home/PlayerContainer'
// import FontAwesome from 'react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import brands from '@fortawesome/fontawesome-free-brands/'
// import { slide as Menu } from 'react-burger-menu'

class App extends Component {

    constructor(props){
        super(props);

        this.state = {
            video: false,
        }
        this.toggleVideo = this.toggleVideo.bind(this);
        this.changeLang=this.changeLang.bind(this);
        this.showSettings= this.showSettings.bind(this);
    }
    componentDidUpdate(props, previousProps) {
        window.scrollTo(0, 0);



    }

    toggleVideo(ev) {

        this.setState({
            video: !this.state.video
        });
    }

    changeLang(lang){
        this.props.actions.setLanguage(lang);
    }
    componentDidMount(props, previousProps) {
        window.scrollTo(0, 0);


    }
    showSettings (event) {
        event.preventDefault();

    }

    render() {
        const {options} = this.props;


        return (
            <div className="App" id="outer-container">
                {/*<Menu pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } >*/}
                        {/*<NavLink exact to={"/"+options.lang+"/"} >Home</NavLink>*/}
                        {/*<NavLink exact to={"/"+options.lang+"/works"}>Works</NavLink>*/}
                    {/*<a href={options.baseUrl+"assets/pdf/brochure_um_agosto2018.pdf"} target="_blank">Brochure</a>*/}
                        {/*<NavLink exact to={"/"+options.lang+"/staff"}>Staff</NavLink>*/}
                {/*</Menu>*/}
                <section className="sections" id="page-wrap">

                  <header className="header">

                    <div className="logo">
                        <Link to={"/"+options.lang+'/'}><img src={options.baseUrl+"assets/img/logo.svg"} alt="logo"/></Link>
                    </div>

                    <nav className="nav-top">
                        <ul>
                            <li className={window.location.pathname === '/'+options.lang+'/' ? 'active': ''}>
                                <NavLink exact to={"/"+options.lang+"/"} >Home</NavLink>
                            </li>
                            <li className={window.location.pathname === ('/'+options.lang+'/works') ? 'active':''}>
                                <NavLink exact to={"/"+options.lang+"/works"}>Works</NavLink>
                            </li>
                            <li className="">
                                <a href="https://www.youtube.com/watch?v=EDdZe1WRWfs" target="_blank">Reel</a>
                            </li>
                            <li className="">
                                <a href={options.baseUrl+"assets/pdf/brochure_um.pdf"} target="_blank">Brochure</a>
                            </li>
                            <li className={window.location.pathname === ('/'+options.lang+'/staff') ? 'active':''}>
                                <NavLink exact to={"/"+options.lang+"/staff"}>Staff</NavLink>
                            </li>

                            <li className="ochotreinta">
                                <a href="https://untrefmedia.com/830studio/" target="_blank">830 Studio</a>
                            </li>
                            {/*<li className={window.location.pathname === ('/'+options.lang+'/contact') ? 'active':''}>*/}
                            {/*    <NavLink exact to={"/"+options.lang+"/contact"}>Contact{options.lang=='es' ? 'o':''}</NavLink>*/}
                            {/*</li>*/}


                            {/* <li className={window.location.pathname === ('/'+options.lang+'/about') ? 'active' : ''}>
                                <NavLink exact to={"/"+options.lang+"/about"}>About</NavLink>
                            </li>
                            <li className={window.location.pathname === ('/'+options.lang+'/contact') ? 'active' :''}>
                                <NavLink  exact to={"/"+options.lang+"/contact"}>Contact</NavLink>
                            </li> */}
                        </ul>
                    </nav>
                    <div className="nav-lang">
                      <ul>
                        <li className={window.location.pathname.startsWith('/es') ? 'active': ''}>
                              <NavLink  to={"/es"} onClick={()=>this.changeLang('es')} >Es </NavLink>
                        </li>
                        <li className={window.location.pathname.startsWith('/en') ? 'active': ''}>
                          <NavLink  to={"/en"} onClick={()=>this.changeLang('en')} >En </NavLink>
                        </li>
                      </ul>
                    </div>
                    {/*<div className="social">*/}
                      {/*<Link to={`//twitter.com/untrefmedia`} target="_blank">*/}
                        {/*<FontAwesomeIcon icon={['fab', 'twitter']} />*/}
                      {/*</Link>*/}
                      {/*<Link to={`//www.facebook.com/untrefmedia`} target="_blank">*/}
                        {/*<FontAwesomeIcon icon={['fab', 'facebook-f']} />*/}
                      {/*</Link>*/}
                      {/*<Link to={`//www.instagram.com/untrefmedia/`}  target="_blank">*/}
                        {/*<FontAwesomeIcon icon={['fab', 'instagram']} />*/}
                      {/*</Link>*/}
                      {/*<Link to={`//www.linkedin.com/company/untref-media`}  target="_blank">*/}
                        {/*<FontAwesomeIcon icon={['fab', 'linkedin']} />*/}
                      {/*</Link>*/}
                    {/*</div>*/}

                    </header>

                    <Switch>
                        <Route exact={true} path="/:lang/" strict={false} component={Home}/>
                        <Route exact={true} path="/:lang/tags/:slug" strict={false} component={Tage}/>
                        <Route exact={true} path="/:lang/t/:slug" strict={false} component={Tag}/>
                        <Route exact={true} path="/:lang/works/:slug" strict={false} component={Work}/>
                        <Route exact={true} path="/:lang/about" strict={false} component={About}/>
                        <Route exact={true} path="/:lang/staff" strict={false} component={About}/>
                        <Route exact={true} path="/:lang/nosotros" strict={false} component={About}/>
                        <Route exact={true} path="/:lang/works" strict={false} component={Works}/>
                        <Route exact={true} path="/:lang/contact" strict={false} component={Contact}/>


                    </Switch>


                    <section className="footer">
                        <div>
                          <p>UNTREF MEDIA</p>
                        </div>
                        <div>

                        </div>
                        <div>
                          <p>info@untrefmedia.com</p><br/>
                          <p><b>Maipú 71</b>, PB<br/>
                          Ciudad Autónoma de Buenos Aires<br/>
                          Buenos Aires, Argentina</p>

                            <div className="social">
                                <Link to={`//twitter.com/untrefmedia`} target="_blank">
                                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                                </Link>
                                <Link to={`//www.facebook.com/untrefmedia`} target="_blank">
                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                </Link>
                                <Link to={`//www.instagram.com/untrefmedia/`}  target="_blank">
                                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                                </Link>
                                <Link to={`//www.linkedin.com/company/untref-media`}  target="_blank">
                                    <FontAwesomeIcon icon={['fab', 'linkedin']} />
                                </Link>
                                <Link to={`//vimeo.com/untrefmedia`} target="_blank">
                                    <FontAwesomeIcon icon={['fab', 'vimeo-v']} />
                                </Link>
                                <Link to={`//www.youtube.com/channel/UCWRwRk8jnnhV_rvivYazSMg`}  target="_blank">
                                    <FontAwesomeIcon icon={['fab', 'youtube']} />
                                </Link>
                            </div>
                        </div>
                        {/* <div className="social">
                            <FontAwesome name='facebook-f' />
                            <FontAwesome name='twitter' />
                            <FontAwesome name='youtube' />
                            <FontAwesome name='vimeo' />
                            <FontAwesome name='linkedin' />
                        </div> */}
                        <div className="untref-logo">
                            <img src={options.baseUrl+'/assets/img/untref-logo.svg'} alt="untref"/>
                        </div>
                    </section>

                </section>
                {this.state.video ? <PlayerContainer toggleVideo={this.toggleVideo}/> : ''}
            </div>
        );
    }
}

App.propTypes = {
    options: PropTypes.object.isRequired,
    tags: PropTypes.array.isRequired,
}

const mapStateToProps = (state,ownProps)=>{
    return {
        options:state.options,
        tags:state.tags
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({},optionActions,itemsActions), dispatch)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(App);
