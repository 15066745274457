import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ProgressiveImage from 'react-progressive-image';
import {bindActionCreators} from "redux";
import * as itemsActions from "../../actions/itemsActions";
import Sponsors from "../Home/Sponsors";

class Item extends React.Component {

    createMarkup(html) {
        return {__html: html};
    }

    b64_to_utf8(str) {
        return decodeURIComponent(escape(window.atob(str)));
    }

    removeHtml(str){

        let div = document.createElement("div");
        div.innerHTML = str;
        return div.textContent || div.innerText || "";

    }

    componentDidMount() {

    }

    render() {
        const {item, options} = this.props;


        let title;

        title = item.title;
        if(options.lang !== 'es'){
            title= item.title_en;
        }

        return (
            <Link to={"/" + options.lang + "/works/" + item.slug} className="project">
                <div className="project-container">
                    <div className="overlay overlay-hidden"></div>
                    <div className="aspect-image">
                        <img src={options.baseUrl + 'assets/img/placeholder712x484.png'} alt="#"/>
                    </div>
                    <div className="background-container">
                        <ProgressiveImage src={item.featured_image_grilla}
                                          placeholder={options.baseUrl + 'assets/img/preloader-712x484.gif'}>
                            {(src) => <img src={src} alt={item.featured_image_grilla}/>}
                        </ProgressiveImage>
                    </div>
                    <div className="data-table">
                        <div className="data-cell">
                            <div className="button">
                                <div className="sprite-anim-go"></div>
                            </div>
                        </div>
                    </div>

                    <div className="project-text">
                        <h3>
                            {title}
                        </h3>
                        {/*<p dangerouslySetInnerHTML={this.createMarkup(this.b64_to_utf8(item.abstract))}>*/}

                        {/*</p>*/}

                    </div>
                </div>
            </Link>
        )
    }
}

class TagE extends React.Component {

    componentDidUpdate(props, previousProps) {
        window.scrollTo(0, 0);

    }




    removeHtml(str){

        let div = document.createElement("div");
        div.innerHTML = str;
        return div.textContent || div.innerText || "";

    }

    componentWillReceiveProps(nextProps) {

        if (this.props.match.params.slug !== nextProps.match.params.slug) {
            //...action here
            // if(this.props.items.length === 0) {
            // this.props.actions.getItemsTx(nextProps.match.params.slug);
            // }
        }
    }

    componentDidMount(props, previousProps) {
        window.scrollTo(0, 0);

        document.title = this.props.tag.name;

        console.log(this.props.tag.description);

        let meta_descript = this.removeHtml(this.props.tag.description);

        document.querySelector('meta[name="description"]').setAttribute("content", meta_descript);

       // this.props.actions.getItemsTx(this.props.match.params.slug);

    }

    shouldComponentUpdate() {
        return true;
    }

    createMarkup(html) {
        return {
            __html: html
        }
    }

    render() {
        const {items, tag, options, tags, ajaxCallInProgress, dictionary, location, match} = this.props;
        let tagname;
        let description;
        tagname = tag.name;
        description = tag.description;
        if (options.lang !== 'es') {
            tagname = tag.name_en;
            description= tag.description_en
        }
        return (
            <div className={tag.slug}>
                <div className="container info-home-container">
                    <article className="info">
                        <div className="info-container">
                            <ul>
                                {tags.filter(t=>t.list).map((t, i) => {

                                        let name;

                                        name = t.name;
                                        if (options.lang !== 'es') {
                                            name = t.name_en;
                                        }

                                    let linka
                                    let target
                                    if(t.linkex !== true) {
                                        linka = '/'+options.lang+'/tags/' + t.slug;
                                    } else {
                                        linka = t.slug;
                                        target = '"_blank"';
                                    }

                                        return (
                                            <li key={'tag-' + t.slug}>
                                                <a href={linka} target={target}>{name}</a>
                                            </li>
                                        )
                                    }
                                )}
                            </ul>
                            <div className="dropdown">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default"
                                            data-toggle="dropdown">{dictionary[options.lang].setCategory}</button>
                                    <span className="caret"></span>
                                    <ul className="dropdown-menu">
                                        {tags.filter(t=>t.list).map((t, i) => {
                                                let name;

                                                name = t.name;
                                                if (options.lang !== 'es') {
                                                    name = t.name_en;
                                                }

                                            let linka
                                            let target
                                            if(t.linkex !== true) {
                                                linka = '/'+options.lang+'/tags/' + t.slug;
                                            } else {
                                                linka = t.slug;
                                                target = '"_blank"';
                                            }

                                                return (
                                                    <li key={'tag-' + t.slug}>
                                                        <a href={linka} target={target}>{name}</a>
                                                    </li>
                                                )
                                            }
                                        )}
                                    </ul>
                                </div>
                            </div>
                            {/* <Link to="#" className="btn-reel">Reel 018</Link> */}
                        </div>
                    </article>
                </div>
                <div className="black-container">
                    <article>
                        <div className="aspect-image">
                            <img src={options.baseUrl + "/assets/img/featured-small.jpg"} alt={tag.name}/>
                        </div>
                        <header>
                            <div className="cont-header">
                                <h1 className="header">
                                    {tagname}
                                </h1>
                                <p dangerouslySetInnerHTML={this.createMarkup(description)}></p>
                            </div>
                        </header>
                    </article>
                </div>
                <section id="home" className="container tag skew">
                    <div className="projects-container">

                        <div className="cont-title">
                            <h2>Mirá todo lo que podemos hacer por vos</h2>
                        </div>
                        <div> {ajaxCallInProgress > 0 ?
                            <div className="loader"><span>{dictionary[options.lang].loading}</span></div> : ""}
                        </div>
                        {tag.items.map((i, index) => {
                            return (<Item key={'item-' + i.id} item={i} options={options}/>)
                        })}
                    </div>
                </section>

                <div className="cont-title">
                    <h2>Clientes que ya confiaron en nosotros</h2>
                </div>
                <section id="sponsors" className="container sponsors">
                    <Sponsors location={location} match={match}/>
                </section>

            </div>
        )
    }
}

TagE.propTypes = {
    tag: PropTypes.object.isRequired,
    // items: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired,
    ajaxCallInProgress: PropTypes.number.isRequired,
    dictionary: PropTypes.object.isRequired,
    location:PropTypes.object.isRequired,
    match:PropTypes.object.isRequired,

}

const mapStateToProps = (state, ownProps) => {

    const tag = state.tags.filter((t) => {
            return t.slug === ownProps.match.params.slug
        }
    );

    return {
        tag: tag[0],
        items: state.items.filter((s) => {

            return s.tags.indexOf(parseInt(tag[0].id, 10)) !== -1;
        }),
        options: state.options,
        tags: state.tags,
        ajaxCallInProgress: state.ajaxCallInProgress,
        dictionary: state.dictionary,
        location:ownProps.location,
        match:ownProps.match,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(itemsActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TagE)
