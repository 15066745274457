import React from "react";
import items from './items'
const baseUrl = "//untrefmedia.com/";
// const baseUrl = "http://localhost:3000/";

export default {
    ajaxCallInProgress: 0,
    options: {
        baseUrl: baseUrl,
        lang: "es",
        loader_url:'//cdnjs.cloudflare.com/ajax/libs/galleriffic/2.0.1/css/loader.gif',
        loader_portfolio_url:'',
    },
    sendMail:null,
    langs: [
        {
            slug: "es",
            label: "Español",

        },
        {
            slug: "en",
            label: "Ingles",
        }
    ],
    items,
    featuredItems: [],
    tags: [
        {
            id: 465,
            slug: "contenidos-audiovisuales",
            name: "Contenidos audiovisuales / Digitales",
            name_en: "Audiovisual / Digital Content",
            description: "",
            description_en: "",
            img: baseUrl + "assets/img/home-cine-y-series.jpg",
            items:[],
            list:true,
            linkex:false,
        },
        {
            id: 463,
            slug: "https://www.behance.net/UNTREFMEDIA",
            name: "Diseño y animación",
            name_en: "Design & Animation",
            description: "Con un equipo multidisciplinario, trabajamos todo el proceso creativo desde la concepción de la idea hasta su entrega final. Nos especializamos en storytelling, ilustración y animación 2D.",
            description_en: "With a multidisciplinary team, we work through the entire creative process, from conception to distribution. We specialize in storytelling, illustration and 2D/3D animation.",
            img: baseUrl + "assets/img/home-animacion.jpg",
            items:[],
            list:true,
            linkex:true,

        },
        {
            id: 464,
            slug: "videojuegos",
            name: "Videojuegos",
            name_en: "Video Games",
            description: "Desarrollamos videojuegos desde 2013, reuniendo a un equipo profesional integral de desarrolladores con más de 15 años de experiencia en la industria. Siempre en busca de la excelencia, la calidad, innovación e ideas creativas, desarrollamos  para plataformas mobile, PC, web y consolas, basado en sus propias IP originales como modelo de negocio principal. Como plan de negocio secundario, también brindamos servicios para terceros (outsourcing), desarrollando todo tipo de videojuegos, incluyendo advergames y edugames basados en la IP del cliente.<br><a class='button' href='https://videogames.untrefmedia.com/' target='_blank'>Ver más</a>",
            description_en: "We have developed video-games since 2013, bringing together a professional team of developers with more 15 years of experience in the industry. We always look for excellence, quality, innovation and creative ideas in order to develop content for mobile platforms, PC, web and game consoles, based on their own original IPs as the main business model. As a secondary business plan, we also provide services for third parties (outsourcing), developing all kinds of video games, including advergames and edugames based on the customer’s IP.<br><a class='button' href='https://videogames.untrefmedia.com/' target='_blank'>View more</a>",
            img: baseUrl + "assets/img/home-videojuegos.jpg",
            items:[],
            list:true,
            linkex:false,
        },
        // {
        //     id: 466,
        //     slug: "diseno",
        //     name: "Diseño",
        //     name_en: "Design",
        //     description: "Contamos historias a través del diseño. Logramos las mejores soluciones adecuadas al contexto, usuarios y clientes. Somos especialistas en comunicación visual, branding y motion graphics.",
        //     description_en: "We tell stories through design. We achieve the best solution for every context, user and client. We specialize in visual communication, branding and motion graphics.",
        //     img: baseUrl + "assets/img/home-diseno.jpg",
        //     items:[],
        //     list:true,
        //
        // },
        {
            id: 467,
            slug: "apps",
            name: "Web y Apps",
            name_en: "Web and Apps",
            description: "Desarrollamos aplicaciones interactivas abordando realidad virtual, realidad aumentada, streaming y video 360°. Creamos experiencias, desarrollamos los sistemas y plataformas de gestión integral para eventos (incluyendo comunicación, inscripción, administración y pagos). Diseñamos y desarrollamos apps web y mobile adaptables a toda necesidad y plataforma, enfocados siempre en la interacción y experiencia del usuario.",
            description_en: "We develop interactive applications applying virtual reality, augmented reality, streaming and 360º video. We create experiences and we develop integral management systems and platforms for events (communication, registration, administration and payments). We design and develop web and mobile apps adaptable to every need and platform, always focused on user interaction and experience.",
            img: baseUrl + "assets/img/home-apps.jpg",
            items:[],
            list:true,
            linkex:false,
        },
        {
            id: 462,
            slug: "contenidos-inmersivos",
            name: "Contenidos inmersivos",
            name_en: "Immersive content",
            description: "Generamos experiencias interactivas en 360°, realidad virtual y recorridos virtuales. Nos enfocamos en brindar experiencias, combinando video 360° de alta definición con mundos 3D generados por computadora (CGI).  Disponemos de una amplia gama de equipos de registro para videos y fotos en 8K y 3D. Tenemos capacidad para transmitir eventos en vivo y en directo de video 360° a 4K y hasta 60 FPS.",
            description_en: "We create interactive experiences in 360º, virtual reality and augmented reality. We are focused on providing experiences combining high definition 360º video and 3D worlds generated by computer (CGI). We have a wide variety of 8K and 3D recording and photography equipment. We are able to broadcast live 360º video in 4K up to 60 FPS.",
            img: baseUrl + "assets/img/home-cont-inm.jpg",
            background:baseUrl + "assets/img/UM360_post-1200x477.jpg",
            background_small:baseUrl + "assets/img/UM360_post-1200x477_small.jpg",
            items:[],
            list:true,
            linkex:false,
        },
        {
            id: 500,
            slug: "comunicacion-y-eventos",
            name: "Comunicación y Eventos",
            name_en: "Comunication & Events",
            description: "Soluciones creativas para eventos corporativos y exposiciones.<br/>" +
                "Desde el concepto hasta la realización de las piezas necesarias para un evento de alto impacto.\n" +
                "Generamos el arte y el diseño de todo lo que sucede ese día y también facilitamos herramientas para su comunicación previa.\n" +
                "Así, tus invitados encuentran toda la información necesaria sobre vos y el acontecimiento y además, los sorprendés con un mega evento desarrollado acorde a tus necesidades y tu presupuesto.\n",
            description_en: "",
            img: baseUrl + "assets/img/home-cont-inm.jpg",
            background:baseUrl + "assets/img/UM360_post-1200x477.jpg",
            background_small:baseUrl + "assets/img/UM360_post-1200x477_small.jpg",
            items:[],
            list:false,
            linkex:false,
        },

    ],

    slides: [
        {
            id: 0,
            title: "Slide 2",
            content: "",
            content_en: "",
            img: "https://umtemp.untrefmedia.com/wp-content/uploads/2022/08/Los-discos-que-nos-cambiaron_Portada-1425x477.png",
            link: "/es/works/los-discos-que-nos-cambiaron",

        },
        {
            id: 1,
            title: "Slide 3",
            content: "",
            content_en: "",
            img: "https://umtemp.untrefmedia.com/wp-content/uploads/2022/09/Homenajes_Portada-1425x477.png",
            link: "/es/works/homenajes",

        },
        {
            id: 2,
            title: "Slide 4",
            content: "",
            content_en: "",
            img: "https://umtemp.untrefmedia.com/wp-content/uploads/2022/09/Artivistas_Portada-1425x477.png",
            link: "/es/works/artivistas",

        },
        {
            id: 3,
            title: "Slide 5",
            content: "",
            content_en: "",
            img: "https://umtemp.untrefmedia.com/wp-content/uploads/2022/09/Integra_Portada-1425x477.png",
            link: "/es/works/integra",

        },
        {
            id: 4,
            title: "Slide 6",
            content: "",
            content_en: "",
            img: "https://umtemp.untrefmedia.com/wp-content/uploads/2022/09/Heraia_Portada-1425x477.png",
            link: "/es/works/heraia",

        },
        {
            id: 5,
            title: "Slide 7",
            content: "",
            content_en: "Monday to friday 4 a 5.30 pm",
            img: "https://umtemp.untrefmedia.com/wp-content/uploads/2022/10/Slide_TEC_sinHS.jpg",
            link: "/es/works/todos-estamos-conectados",

        }

    ],
    sponsors: [

        {
            id: 1110,
            title: "TNT Sports",
            img: baseUrl + "assets/img/sponsors/tnt.svg",
            link: "#",
            home: true,
        },
        {
            id: 1,
            title: "Loop",
            img: baseUrl + "assets/img/sponsors/loop.svg",
            link: "#",
            home: false,
        },
        {
            id: 2,
            title: "Isuzu",
            img: baseUrl + "assets/img/sponsors/isuzu.svg",
            link: "#",
            home: false,
        },
        {
            id: 3,
            title: "Chevrolet",
            img: baseUrl + "assets/img/sponsors/chevrolet.png",
            link: "#",
            home: false,
        },

        {
            id: 5,
            title: "Sasami",
            img: baseUrl + "assets/img/sponsors/sasami.png",
            link: "#",
            home: false,
        },
        {
            id: 6,
            title: "Disney",
            img: baseUrl + "assets/img/sponsors/disney.png",
            link: "#",
            home: false,
        },
        {
            id: 7,
            title: "Abbott",
            img: baseUrl + "assets/img/sponsors/abbott.png",
            link: "#",
            home: false,
        },
        {
            id: 8,
            title: "CapMedia",
            img: baseUrl + "assets/img/sponsors/capmedia.png",
            link: "#",
            home: true,
        },
        {
            id: 9,
            title: "OIM",
            img: baseUrl + "assets/img/sponsors/oim.png",
            link: "#",
            home: true,
        },
        {
            id: 10,
            title: "Spawn Digital",
            img: baseUrl + "assets/img/sponsors/spawn-digital.png",
            link: "#",
            home: true,
        },
        {
            id: 11,
            title: "Natura",
            img: baseUrl + "assets/img/sponsors/natura.png",
            link: "#",
            home: false,
        },
        {
            id: 12,
            title: "Coca Cola FM",
            img: baseUrl + "assets/img/sponsors/cocacolaFM.png",
            link: "#",
            home: true,
        },
        {
            id: 13,
            title: "Philips",
            img: baseUrl + "assets/img/sponsors/philips.png",
            link: "#",
            home: true,
        },
        {
            id: 14,
            title: "Thomson Reuters",
            img: baseUrl + "assets/img/sponsors/thomson-reuters.png",
            link: "#",
            home: true,
        },
        {
            id: 15,
            title: "Nintendo",
            img: baseUrl + "assets/img/sponsors/nintendo.svg",
            link: "#",
            home: true,
        },
        {
            id: 16,
            title: "PlayStation",
            img: baseUrl + "assets/img/sponsors/playstation.svg",
            link: "#",
            home: true,
        },
        {
            id: 17,
            title: "GCBA",
            img: baseUrl + "assets/img/sponsors/gcba.png",
            link: "#",
            home: true,
        },
        {
            id: 18,
            title: "Google",
            img: baseUrl + "assets/img/sponsors/google.png",
            link: "#",
            home: true,
        },
        {
            id: 19,
            title: "Apple",
            img: baseUrl + "assets/img/sponsors/apple.png",
            link: "#",
            home: true,
        },
        {
            id: 20,
            title: "CCK",
            img: baseUrl + "assets/img/sponsors/cck.png",
            link: "#",
            home: false,
        },
        {
            id: 21,
            title: "Cippec",
            img: baseUrl + "assets/img/sponsors/cippec.png",
            link: "#",
            home: true,
        },
        {
            id: 22,
            title: "UCA",
            img: baseUrl + "assets/img/sponsors/uca.png",
            link: "#",
            home: true,
        },
        {
            id: 23,
            title: "LG",
            img: baseUrl + "assets/img/sponsors/lg.png",
            link: "#",
            home: false,
        },
        {
            id: 24,
            title: "Bayer",
            img: baseUrl + "assets/img/sponsors/bayer.png",
            link: "#",
            home: false,
        },
        {
            id: 25,
            title: "National Geographic",
            img: baseUrl + "assets/img/sponsors/sponsor-1.svg",
            link: "#",
            home: true,
        },
        {
            id: 26,
            title: "Netflix",
            img: baseUrl + "assets/img/sponsors/sponsor-2.svg",
            link: "#",
            home: true,
        },
        {
            id: 27,
            title: "La Nacion +",
            img: baseUrl + "assets/img/sponsors/sponsor-3.svg",
            link: "#",
            home: true,
        },
        {
            id: 28,
            title: "Señal Colombia",
            img: baseUrl + "assets/img/sponsors/sponsor-4.svg",
            link: "#",
            home: true,
        },
        {
            id: 29,
            title: "Encuentro",
            img: baseUrl + "assets/img/sponsors/sponsor-5.svg",
            link: "#",
            home: true,
        },
        {
            id: 30,
            title: "Paka Paka",
            img: baseUrl + "assets/img/sponsors/sponsor-6.svg",
            link: "#",
            home: true,
        },
        {
            id: 31,
            title: "EducAr",
            img: baseUrl + "assets/img/sponsors/sponsor-7.svg",
            link: "#",
            home: true,
        },
        {
            id: 32,
            title: "Film Suez",
            img: baseUrl + "assets/img/sponsors/sponsor-8.svg",
            link: "#",
            home: true,
        },
        {
            id: 33,
            title: "YPF",
            img: baseUrl + "assets/img/sponsors/sponsor-9.svg",
            link: "#",
            home: true,
        }, {
            id: 34,
            title: "Presidencia de la Nación",
            img: baseUrl + "assets/img/sponsors/sponsor-10.svg",
            link: "#",
            home: true,
        }, {
            id: 35,
            title: "Televisión Pública",
            img: baseUrl + "assets/img/sponsors/sponsor-11.svg",
            link: "#",
            home: true,
        },
        {
            id: 36,
            title: "Telefónica",
            img: baseUrl + "assets/img/sponsors/sponsor-12.svg",
            link: "#",
            home: false,
        },
        {
            id: 37,
            title: "Amnistía Internacional",
            img: baseUrl + "assets/img/sponsors/amnistia_internacional.png",
            link: "#",
            home: false,
        },
        {
            id: 38,
            title: "Embajada de Italia en Buenos Aires",
            img: baseUrl + "assets/img/sponsors/embajada_Italia.png",
            link: "#",
            home: false,
        },
        {
            id: 39,
            title: "Fundación Vida Silvestre",
            img: baseUrl + "assets/img/sponsors/fundacion_vida_silvestre.png",
            link: "#",
            home: false,
        },
        {
            id: 40,
            title: "ITA",
            img: baseUrl + "assets/img/sponsors/ita.png",
            link: "#",
            home: false,
        },
    ],
    connectivity: 0,

    dictionary:{
        es:{
            loading:'Cargando...',
            setCategory:'Seleccionar Categoría',
            us:'Nosotros',
            director: 'Director general',
            general_producer: 'Productor general',
            institutional_relations: 'Relaciones Institucionales',
            international_contents: 'CONTENIDOS Y NEGOCIOS INTERNACIONALES',
            content_director: 'DIRECTOR DE CONTENIDOS',
            prospects_development: 'COMUNICACIÓN',
            content_production: 'DESARROLLO DE CONTENIDOS / ANIMACIÓN',
            digital_content: 'PRODUCCIÓN DE CONTENIDOS DIGITALES',
            cinema: 'CINE',
            project_management: 'GESTIÓN DE PROYECTOS /\n' +
                'ADMINISTRACIÓN GENERAL',
            video_games: 'VIDEOJUEGOS',
            apps_web: 'SOFTWARE / WEB / APPS / VIDEOJUEGOS',
            social_media: 'REDES SOCIALES',
            audiovisual_content: 'PRODUCCIÓN DE CONTENIDOS /\n' +
                'AUDIOVISUALES / DIGITALES',
            script: 'GUIÓN',
            formats_development: 'DESARROLLO DE FORMATOS',
            photo: 'FOTOGRAFIA',
            content_management: 'GESTIÓN DE CONTENIDOS',
            estacion: 'RADIO ESTACIÓN UNTREF',
            project_development: 'DESARROLLO DE PROYECTOS Y MERCADOS',
            art_design: 'ARTE Y DISEÑO',
            musical_prod: 'PRODUCCION MUSICAL: BSO Y SOUND ALIKE',
            media_management: 'GESTIÓN DE LA MEDIA',
            technical: 'TÉCNICA',
            shootings: 'RODAJES Y EXTERIORES',
            postproduction: 'POSTPRODUCCIÓN\n' +
                'DE IMAGEN Y SONIDO',
            contact:'Contacto',
            name: 'Nombre y Apellido',
            phone: 'Teléfono',
            message: 'Mensaje',
            send: 'Enviar',
            successmsg: 'Tu mensaje ha sido enviado!',
            errormsg: 'error',
            email_error:"email no válido",
            short_text: "El mensaje debe tener al menos 15 caracteres",
        },
        en:{
            loading:'Loading...',
            setCategory:'Select Category',
            us:'Us',
            director: 'GENERAL DIRECTOR',
            general_producer: 'GENERAL PRODUCER',
            institutional_relations: 'INSTITUTIONAL RELATIONS',
            international_contents: 'CONTENT AND INTERNATIONAL BUSINESS',
            content_director: 'CONTENTS DIRECTOR',
            prospects_development: 'DEVELOPMENT OF PROSPECTS / MARKETS',
            content_production: 'CONTENT PRODUCTION / ANIMATION',
            digital_content: 'DIGITAL CONTENT PRODUCTION',
            cinema: 'CINEMA',
            project_management: 'PROJECT MANAGEMENT<br />AND ADMINISTRATION',
            video_games: 'VIDEO-GAMES',
            apps_web: 'SOFTWARE / WEB / APPS /VIDEOGAMES',
            social_media: 'SOCIAL MEDIA',
            audiovisual_content: 'AUDIOVISUAL CONTENT<br />PRODUCTION',
            script: 'SCRIPT',
            formats_development: 'FORMATS DEVELOPMENT',
            photo: 'PHOTO',
            content_management: 'CONTENT MANAGEMENT',
            estacion: 'ESTACION UNTREF RADIO',
            project_development: 'PROJECT DEVELOPMENT & MARKETS',
            art_design: 'ART & DESIGN',
            musical_prod: 'MUSICAL PRODUCTION: OST AND SOUND ALIKE',
            media_management: 'MEDIA MANAGEMENT',
            technical: 'TECHNICAL DEPARTMENT',
            shootings: 'SHOOTINGS & EXTERIORS',
            postproduction: 'POSTPRODUCTION\n' +
                'OF IMAGE AND SOUND',
            contact:'Contact',
            name: 'Full name',
            phone: 'Phone',
            message: 'Message',
            send: 'Send',
            successmsg: 'Your message has been sent!',
            errormsg: 'error',
            email_error:"Not valid email",
            short_text: "The message should be at least 15 characters long",

        }
    }

}
