import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ProgressiveImage from 'react-progressive-image';
import {bindActionCreators} from "redux";
import * as itemsActions from "../../actions/itemsActions";

class Item extends React.Component {

  createMarkup(html) {
      return {__html: html};
  }
  b64_to_utf8(str) {
      return decodeURIComponent(escape(window.atob(str)));
  }

    render() {
        const {item, options} = this.props
        return (
            <Link to={"/"+options.lang+"/works/" + item.slug} className="project">
                <div className="project-container">
                    <div className="overlay overlay-hidden"></div>
                    <div className="aspect-image">
                        <img src={options.baseUrl+'assets/img/placeholder712x484.png'} alt="#"/>
                    </div>
                    <div className="background-container">
                        <ProgressiveImage src={item.featured_image_grilla} placeholder={options.baseUrl+'assets/img/preloader-712x484.gif'}>
                            {(src) => <img src={src} alt={item.featured_image_grilla}/>}
                        </ProgressiveImage>

                    </div>
                    <div className="data-table">
                        <div className="data-cell">
                            <div className="button">
                              <div className="sprite-anim-go"></div>
                            </div>
                        </div>
                    </div>

                    <div className="project-text">
                        <h3>
                            {item.title}
                        </h3>
                        {/*<p dangerouslySetInnerHTML={this.createMarkup(this.b64_to_utf8(item.abstract))}>*/}
                            {/* {item.abstract} */}

                        {/*</p>*/}
                    </div>
                </div>
            </Link>
        )
    }
}

class Works extends React.Component {


    componentWillReceiveProps(nextProps) {

        if(this.props.match.params.slug !== nextProps.match.params.slug) {
            //...action here
            // if(this.props.items.length === 0) {
            // this.props.actions.getItems();
            // }
        }
    }



    componentDidUpdate(props, previousProps) {
        window.scrollTo(0, 0);
    }

    componentDidMount(props, previousProps) {
        window.scrollTo(0, 0);
        // this.props.actions.getItems();
    }
    shouldComponentUpdate(){
        return true;
    }
    render() {
        const {items, options, tags,ajaxCallInProgress, dictionary} = this.props;
        return (
            <div>
                <div className="container info-home-container">
                    <article className="info">
                        <div className="info-container">

                            <ul>

                                {tags.filter(t=> t.list).map((t, i) => {


                                    let tagname = t.name;

                                    if (options.lang !== 'es') {
                                        tagname = t.name_en;

                                    }

                                    let linka
                                    let target
                                    if(t.linkex !== true) {
                                        linka = '/'+options.lang+'/tags/' + t.slug;
                                    } else {
                                        linka = t.slug;
                                        target = '"_blank"';
                                    }



                                        return (
                                    <li>
                                    <a key={'tag-' + t.slug} href={linka} target={target}>{tagname}</a>
                                    </li>
                                        )
                                    }
                                )}

                                <li><a href="//untrefmedia.com/umstreaming" target="_blank">Streaming</a></li>
                            </ul>


                            <div className="dropdown">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default" data-toggle="dropdown">
                                        { dictionary[options.lang].setCategory}
                                    </button>
                                    <span className="caret"></span>
                                    <ul className="dropdown-menu">
                                        {tags.filter(t=>t.list).map((t, i) => {

                                                let tagname = t.name;


                                                if (options.lang !== 'es') {
                                                    tagname = t.name_en;

                                                }

                                            let linka
                                            let target
                                            if(t.linkex !== true) {
                                                linka = '/'+options.lang+'/tags/' + t.slug;
                                            } else {
                                                linka = t.slug;
                                                target = '"_blank"';
                                            }

                                                return (
                                                    <li key={'tag-' + t.slug}>
                                                        <a key={'tag-' + t.slug} href={linka} target={target}>{tagname}</a>
                                                    </li>
                                                )
                                            }
                                        )}

                                        <li><a href="//untrefmedia.com/umstreaming" target="_blank">Streaming</a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
                <section id="works" className="container tag skew">

                    <div className="overlay overlay-hidden"></div>

                    <div className="projects-container">
                        { ajaxCallInProgress > 0 ? <div className="loader"> <span><img src={options.loader_url} alt={dictionary[options.lang].loading}/></span></div> : ""  }
                        {items.map((i, index) => {
                            return (<Item key={'item-' + i.id} item={i} options={options}/>)
                        })}
                    </div>
                </section>
                <br/>
                <br/>

            </div>

        )
    }
}

Works.propTypes = {


    items: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    ajaxCallInProgress:PropTypes.number.isRequired,
    dictionary:PropTypes.object.isRequired,

}


const mapStateToProps = (state, ownProps) => {


    return {

        items: state.items,
        options: state.options,
        tags:state.tags,
        ajaxCallInProgress:state.ajaxCallInProgress,
        dictionary:state.dictionary,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(itemsActions, dispatch)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Works)
