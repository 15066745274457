import React from 'react';
import Slider from "react-slick";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ProgressiveImage from 'react-progressive-image';
import {Link} from "react-router-dom";
// import lifecycle from "react-pure-lifecycle";

// const SlideItem = ({item, options, isMobile}) => {
class SlideItem extends React.Component {

    // const backgroundStyle = {
    //     background:"url('"+item.featured+"')",
    //     backgroundSize:"cover",
    // }

    state = {
        isSupportWebM: true,
    }

    componentDidMount() {
        if(document.querySelectorAll('video').length >0){
            const videoElement = document.querySelectorAll('.video-index-'+this.props.index)[0];
            videoElement.addEventListener('error', () => {
                this.setState({
                    isSupportWebM: false,
                });
            });
        }

    }

    createMarkup(html) {
        return {
            __html: html
        }
    }

    render() {


        console.log(this.state.isSupportWebM);
        const {item, options, isMobile,index} = this.props;


        let content
        if(item){
            content= item.content;
            if(options.lang!== 'es'){
                content = item.content_en
            }


        }
        return (
            <div className={"slide-content slide-" + item.id}>
                <div className="aspect-image">
                    <img src={options.baseUrl + "/assets/img/featured-small.jpg"} alt={item.title}/>
                </div>
                <div className="slider-background">

                    {/*<ProgressiveImage src={item.featured} placeholder={options.baseUrl + 'assets/img/fallback-small.jpg'}>*/}
                    {/*{(src) => <img src={src} alt='an image'/>}*/}
                    {/*</ProgressiveImage>*/}
                    {/*<img src={item.featured} alt="x"/>*/}


                    {/* {isMobile ? */}

                        <ProgressiveImage src={item.img}
                                          placeholder={options.baseUrl + 'assets/img/slider-1-small.jpg'}>
                            {(src) => <img src={src} alt='an image'/>}
                        </ProgressiveImage>
                        {/* : */}

                        {/* <video className={"showreel-video "+"video-index-"+index }loop="true" autoPlay="true" preload="" muted=""
                        >
                            <source
                                src={options.baseUrl + "assets/video/home2017.mp4"}
                                type="video/mp4"/>
                            <img src="/images/product/product-parent-hero.jpg" title="Your browser does not support the <video> tag" />
                        </video>
                    } */}
                </div>
                <div className="overlay"></div>
                {/*<Link to={'/'+options.lang+'/'+item.link} className="slide-content-absolute">*/}
                <a href={item.link} className="slide-content-absolute">

                    <article>
                        {/*<h1>{item.title}</h1>*/}
                        <p dangerouslySetInnerHTML={this.createMarkup(content)}>

                        </p>
                        <div className="slide-index">
                            0{parseInt(index,10) + 1}
                        </div>
                    </article>

                </a>


            </div>
        )
    }
}

class Slides extends React.Component {

    render() {


        const {options, slides, isMobile} = this.props;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
        };


        return (
            <Slider {...settings}>
                {slides.map((item, index) => {
                    return (<SlideItem key={item.id} index={index} item={item} options={options} isMobile={isMobile}/>);
                })}
            </Slider>
        );
    }
}

Slides.propTypes = {
    slides: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired,
}
const mapStateToProps = (state, ownProps) => {

    return {
        slides: state.slides,
        options: state.options,
    }

}

export default connect(mapStateToProps)(Slides)
