import React from 'react';
import { Link} from 'react-router-dom';
import FontAwesome from 'react-fontawesome';

import ProgressiveImage from 'react-progressive-image';


const FeaturedTag = ({item,options}) => {
    let name
    if(item){
        name = item.name;
        if(options.lang!== 'es'){
            name = item.name_en
        }


    }
    let linka
    let target
    if(item.linkex !== true) {
        linka = '/'+options.lang+'/tags/'+item.slug;
    } else {
        linka = item.slug;
        target = '"_blank"';
    }

    return (
        <a href={linka} target={target} className="project">
            <div className="project-container">
                <div className="overlay overlay-hidden">
                    <div className="data-table">
                        <div className="data-cell">
                            <div className="button">
                                <FontAwesome name='angle-double-down' />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="aspect-image">
                    <img src={options.baseUrl+'assets/img/placeholder712x484.png'} alt="#"/>
                </div>

                <div className="background-container">
                    <ProgressiveImage src={item.img} placeholder={options.baseUrl+'assets/img/preloader.gif'}>
                        {(src) => <img src={src} alt={item.img}/>}
                    </ProgressiveImage>

                </div>


                <div className="project-text">
                    <h3>
                        { name}
                    </h3>
                </div>
            </div>
        </a>
    )
}

export default FeaturedTag
