import * as actiontypes from './actionTypes';
// import Axios from 'axios';

export const setConnectivity = () => {
    console.log(4);
    return (dispatch, getStore) => {
        let config =getStore('config');

            let startTime, endTime, fileSize;

// Set up the AJAX to perform
            const xhr = new XMLHttpRequest();

// Rig the call-back... THE important part
            xhr.onreadystatechange = () => {


                // we only need to know when the request has completed
                if (xhr.readyState === 4 && xhr.status === 200) {

                    // Here we stop the timer & register end time
                    endTime = (new Date()).getTime();

                    // Also, calculate the file-size which has transferred
                    fileSize = xhr.responseText.length;

                    // Calculate the connection-speed
                    const speed = (fileSize * 8) / ((endTime - startTime) / 1000) / 1024;

                    // Report the result, or have fries with it...

                    dispatch({type: actiontypes.SET_CONNECTIVITY, speed: speed});
                }
            }

            // startTime = (new Date()).getTime();
            // xhr.open("GET","/img/amigos-featured.jpg", true);
            // xhr.send();

        }
    }