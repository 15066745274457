import * as types from './actionTypes';
import axios from 'axios';
import {beginAjaxCall,ajaxCallError} from "./ajaxStatusActions";



export function loadTagItemsSuccess(tag,items) {
    return {type: types.GET_TAG_ITEMS_SUCCESS, items:items, tag:tag};
}

export function loadItemsSuccess(items) {
    return {type: types.GET_ITEMS_SUCCESS, items};
}

export const getItems =()=>{

  return (dispatch,getState)=> {
      dispatch(beginAjaxCall());
      // console.log(getState().filters);
      //dispatch(beginAjaxCall());

      axios({
          method: 'get',
          url: 'https://rest.untrefmedia.com/ports',
           })
      // axios.post('//localhost:8000/api/items/')
          .then(function (response) {
              //dispatch action


              dispatch(loadItemsSuccess(response.data));
          })
          .catch(function (error) {
              // throw error
              dispatch(ajaxCallError());
          });
    }
}

export const getItemsTx =(tax)=>{

    return (dispatch,getState)=> {
        dispatch(beginAjaxCall());

        axios({
            method: 'get',
            url: 'https://rest.untrefmedia.com/ports-tx/'+tax,
        })
        // axios.post('//localhost:8000/api/items/')
            .then(function (response) {
                //dispatch action


                dispatch(loadTagItemsSuccess(tax,response.data));
            })
            .catch(function (error) {
                // throw error
                dispatch(ajaxCallError());
            });
    }
}

export const getItem =(slug)=>{

    return (dispatch,getState)=> {
        dispatch(beginAjaxCall());

        axios({
            method: 'get',
            url: 'https://rest.untrefmedia.com/ports/'+slug,
        })

            .then(function (response) {
                //dispatch action


                dispatch(loadItemsSuccess(response.data));
            })
            .catch(function (error) {
                // throw error
                dispatch(ajaxCallError());
            });
    }
}
